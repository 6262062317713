<template>
  <div class="home">
    <div class="home_left">
      <Menu ref="MenuRef"
            :menus="menus" />
    </div>
    <div class="home_right"
         id="view_warp">
      <div class="top_nav">
        <div class="left_icon">
          <i @click="show"
             v-if="!isCollapse"
             class="el-icon-s-fold"></i>
          <i @click="show"
             v-else
             class="el-icon-s-unfold"></i>
          <Navigation ref="navgationRef" />
        </div>
        <div class="right_select">
          <el-dropdown>
            <span class="el-dropdown-link">
              <el-avatar :size="35"
                         :src="circleUrl"></el-avatar>
              <span style="cursor: pointer;">{{username}}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native.prevent="editPassword">修改密码</el-dropdown-item>
              <!-- <el-dropdown-item @click.native.prevent="open">关于</el-dropdown-item> -->
              <el-dropdown-item @click.native.prevent="logOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="view_warp">
        <transition mode="out-in">
          <!-- <router-view></router-view> -->
          <keep-alive :include="keepList"
                      :max="5">
            <router-view></router-view>
          </keep-alive>
        </transition>
      </div>
    </div>

    <el-dialog title="修改密码"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-form :model="form"
               label-width="100px"
               ref="FormRef"
               :rules="rules">
        <el-row>
          <el-form-item label="输入新密码"
                        prop="password">
            <el-input clearable
                      placeholder="密码"
                      v-model="form.password"
                      type="password" />
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="确认密码"
                        prop="password_again">
            <el-input clearable
                      placeholder="密码"
                      v-model="form.password_again"
                      type="password" />
          </el-form-item>
        </el-row>
      </el-form>
      <el-row style="text-align:center">
        <el-button type="primary"
                   @click="submit">确定</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import Menu from '@/components/menu.vue'
import Navigation from '../components/navgations/navigation.vue'
import { mapMutations, mapState } from 'vuex'


export default {
  name: 'Home',
  components: {
    Menu,
    Navigation
  },
  created () {
    // 直接拿home页面的子路由做渲染
    // let menus = JSON.parse(window.localStorage.getItem('menuList'))
    // this.menus = menus
  },
  computed: {
    ...mapState(['isCollapse']),
    ...mapState('menu/', {
      'menus': 'menuList'
    })
  },
  mounted () {
    var that = this
    document.addEventListener('plusready', function (a) { //等待plus ready后再调用5+ API：
      plus.key.addEventListener('backbutton', function () { //监听返回键
        if (this.$router.path == '/homepage') {
          plus.runtime.quit();
        } else {
          that.$router.go(-1)
        }
      }, false);
    });

  },
  data () {
    var password_again1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.password_again) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      form: {
        password: '',
        password_again: ''
      },
      keepListAll: ['weaknessStatic', 'studentStatistics'],//需要缓存的页面
      keepList: ['weaknessStatic', 'studentStatistics'],//当前缓存的页面
      rules: {
        password_again: [
          { validator: password_again1, required: true, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 10, message: '长度在 6 到 10 个字符', trigger: 'blur' }
        ],

      },
      dialogVisible: false,
      username: window.localStorage.getItem('userName'),
      tags: [],
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    }
  },
  methods: {
    ...mapMutations(['CHECK_COLLAPSE']),
    show () {
      this.CHECK_COLLAPSE()
    },
    logOut () {
      window.localStorage.clear()
      window.sessionStorage.removeItem('menuList')
      this.$router.push('/login')
      this.$notify({
        title: '提示',
        message: '退出登录成功!',
        type: 'success'
      });
    },

    toPage (item) {
      if (this.$route.path == item.path) {
        return
      }
      this.$router.push(item)
    },
    open () {
      // console.log('456s');
      // this.$alert('这是一段内容', '标题名称', {
      //   confirmButtonText: '确定',
      //   callback: action => {
      //     this.$message({
      //       type: 'info',
      //       message: `action: ${action}`
      //     });
      //   }
      // });
    },
    editPassword () {
      this.dialogVisible = true
    },
    submit () {
      this.$refs.FormRef.validate(val => {
        if (!val) return
        this.$http({
          url: '/api/v1/user/edit',
          method: 'post',
          data: this.form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '修改成功!',
            type: 'success'
          });
          this.handleClose()
          this.logOut()
        })
      })
    },
    handleClose () {
      this.$refs.FormRef.resetFields()
      this.dialogVisible = false
    },
    checkRoute (pathList, name, to) {
      let bool = true
      for (let i = 0; i < pathList.length; i++) {
        if (pathList[i] == to.path) {
          bool = false
        }
      }
      if (!bool) {
        return
      }
      else {
        let index = this.keepList.indexOf(name)
        if (index != -1) {
          this.keepList.splice(index, 1)
          return bool
        }
      }
    }
  },
  watch: {
    $route (to, from) {
      this.keepList = JSON.parse(JSON.stringify(this.keepListAll))
      // if (to.path != '/studentCondition/weakness/detail' && to.path != '/studentCondition/weakness') {
      //   let index = this.keepListAll.indexOf('weaknessStatic')
      //   if (index != -1) {
      //     this.keepList.splice(index, 1)
      //     console.log(this.keepList)
      //   }
      // }
      // 设置从详情页面返回才缓存 【本页路径，详情路径】，组件名
      this.checkRoute(['/studentCondition/weakness/detail', '/studentCondition/weakness'], 'weaknessStatic', to)
      this.checkRoute(['/studentCondition/statistics', '/studentCondition/statistics/subject', '/studentCondition/statistics/list'], 'studentStatistics', to)
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  overflow-x: hidden !important;
  display: flex;
  position: relative;
  .home_left {
    background: #3f9eff;
    transition: all 0.5s ease;
  }
  .home_right {
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    flex: 1;
    .top_nav {
      width: 100%;
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      justify-content: space-between;
      z-index: 2000;
      top: 0;
      .left_icon {
        flex: 2;
        display: flex;
        align-items: center;
        i {
          cursor: pointer;
          font-size: 20px;
        }
      }
      .right_select {
        flex: 1;
        text-align: right;
      }
    }
  }
}
.el-dropdown-link {
  display: flex;
  align-items: center;
}
.el-dropdown {
  margin-right: 30px;
}
.el-avatar {
  margin: 0 5px;
}

.el-dropdown-link {
  display: flex;
  align-items: center;
}
.el-dropdown {
  margin-right: 30px;
}
.el-avatar {
  margin: 0 5px;
}
.tags {
  width: 100%;
  overflow: auto;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  padding: 3px 0;
  & ::v-deep .el-tag {
    box-shadow: 1px 1px 1px 1px #cebcbc;
    cursor: pointer;
  }
  .el-tag:hover {
    position: relative;
    left: 1px;
    bottom: 1px;
  }
}

::v-deep .el-menu {
  border-right: none;
  z-index: 1000;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

::v-deep .el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: 5px 8px 12px 0 #e6e6e6;
}

.view_warp {
  height: calc(100vh - 80px) !important;
  overflow: auto;
  padding: 15px;
  padding-bottom: 0 !important;
  position: relative;
  overflow-x: hidden;
}
::v-deep .el-table thead {
  color: black !important;
}

::v-deep .el-table--border th,
.el-table__fixed-right-patch {
  background: #f2f2f2 !important;
}

::v-deep .el-breadcrumb {
  display: inline-block;
}

::v-deep .el-form-item__label {
  font-size: 15px !important;
}

::v-deep
  .el-table--striped
  .el-table__body
  tr.el-table__row--striped.current-row
  td {
  background: #d8eaff !important;
}
::v-deep .el-table__body tr.current-row > td {
  background: #d8eaff !important;
}

::v-deep .el-loading-mask {
  z-index: 2100;
}

::v-deep .edui-default .edui-toolbar .edui-combox .edui-combox-body {
  max-height: 20px;
}
::v-deep .edui-default .edui-button-body,
.edui-splitbutton-body,
.edui-menubutton-body,
.edui-combox-body {
  max-height: 20px;
}
/*图片预览 缩略图*/
.preview figure {
  float: left;
  width: 30%;
  height: calc(30vw - 0px);
  margin: 1.5%;
}

.preview figure img {
  width: 100%;
}

::v-deep .el-button {
  padding: 0;
  width: 90px;
  height: 36px;
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}

::v-deep .el-loading-spinner .el-loading-text {
  font-size: 16px;
}

::v-deep .el-loading-spinner i {
  font-size: 39px;
  color: #409eff;
}
</style>