<template>
  <div>
    <el-menu :collapse="isCollapse"
             unique-opened
             :default-active="$route.path"
             class="el-menu-vertical-demo menu_hidden_scroll"
             :text-color="isCollapse?'black':'white'">
      <div class="logo">
        <img src="@/assets/homepage/logo.png"
             v-show="!isCollapse"
             id="logo"
             alt="">
      </div>
      <div v-for="item in menus"
           style="overflow:hidden"
           :key="item.name">
        <menuGroup v-if="item.child"
                   :group="item" />
        <MenuItem :item="item"
                  v-else />
      </div>
    </el-menu>
  </div>
</template>

<script>
import menuGroup from './menuGroup.vue'
import MenuItem from './menuItem.vue'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'Menu',
  props: ['menus'],
  components: {
    menuGroup, MenuItem,
  },
  data () {
    return {
      width: '215px',
    }
  },
  computed: {
    ...mapState(['isCollapse'])
  },
  watch: {
    '$route.path': {
      handler () {
        if (document.body.offsetWidth < 1300) {
          this.show(1)
        }
      },
      deep: true
    }
  },
  mounted () {
    if (document.body.offsetWidth < 1300) {
      this.show()
    }
  },
  methods: {
    ...mapMutations([
      'CHECK_COLLAPSE'
    ]),
    show (num) {
      if (num && !this.isCollapse) {
        // this.CHECK_COLLAPSE()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-menu-item-group__title {
  padding: 0;
}

.logo {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  img {
    transform: scale(0.9);
    margin-left: -30px;
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 215px;
  min-height: 400px;
}

/*隐藏文字*/
::v-deep .el-menu--collapse .el-submenu__title span {
  display: none;
}
::v-deep .el-menu--collapse .el-submenu.is-active {
  border-right: 4px solid #fff;
}
/*隐藏 > */
::v-deep .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}
::v-deep .el-menu {
  background: #559de7;
}

::v-deep .el-submenu .el-menu-item {
  background: #89c4ff;
}

::v-deep .left_menu_icon {
  margin-right: 10px;
}

::v-deep .el-submenu__title i {
  color: white;
}
.menu_hidden_scroll {
  height: 100vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

::v-deep .el-menu--vertical {
  color: red !important;

  div {
  }
}
</style>