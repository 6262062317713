<template>
  <el-breadcrumb separator="/">
    <!-- <el-breadcrumb-item :to="{ path: '/homepage' }">首页</el-breadcrumb-item> -->
    <el-breadcrumb-item v-for="(item,index) in navigation"
                        @click.native.prevent="powerCut(item,index)"
                        :key="index"> {{item.meta.title}} </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  data () {
    return {

    }
  },
  computed: {
    ...mapState(['navigation'])
  },
  beforeDestroy () {
    // console.log(this.navigation)
    // window.sessionStorage.setItem("navigation", JSON.stringify(this.navigation))
  },
  mounted () {
    // let navigation = JSON.parse(window.sessionStorage.getItem("navigation")) || []
    // this.SET_NAVIGATION(navigation)
  },
  methods: {
    // ...mapMutations(['SET_NAVIGATION', 'CLEAR_NAVIGATION']),
    powerCut (item, index) {
      if ( this.$route.path == item.path) {
        return
      }
      else if (item.meta.unique_auth == 'About.vue') {
        return
      }
      this.$router.push({ path: item.path })
    }
  }
}
</script>

<style lang="scss" scoped>
// 面包屑
.el-breadcrumb {
  margin-left: 15px;
  font-size: 15px;

  ::v-deep .el-breadcrumb__inner {
    &:hover {
      color: #409eff;
      cursor: pointer;
    }
  }
}

::v-deep .el-breadcrumb {
  display: inline-block;
}
</style>